import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const subsidiesRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.SUBSIDIES.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_READ,
        ]),
      ],
    },
    path: '/subsidy',
    component: () => import('@/modules/subsidy/views/SubsidyOverviewView.vue'),
  },
  {
    name: ROUTE_NAME.SUBSIDIES.DETAIL,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_READ,
        ]),
      ],
    },
    path: '/subsidy/:uuid',
    component: () => import('@/modules/subsidy/views/SubsidyDetailView.vue'),
  },
]
